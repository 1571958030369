import React, { useContext } from 'react';
import { get, isEmpty } from 'lodash';
import { Drawer } from '@material-ui/core';
import styles from './useNavigationDrawer.module.scss';
import getWindow from '../libs/getWindow';
import CartIcon from '../components/app/classes-command-bar/cart-icon';
import { AppContext } from '../components/layout/AppLayout';
import navigate from '../libs/navigate';
import closeSymbol from '../images/icons/icon-close.svg';
import { getViewingPlatform } from '../libs/getViewingPlatform';

const { location } = getWindow();
const navigateDrawer = (status, force = false) => {
  if (!isEmpty(location)) {
    const { search } = location;
    const browserPath = `${get(location, 'pathname', '')}`;
    const searchParams = new URLSearchParams(search);
    if (status) {
      if (force) {
        searchParams.set('drawer', `${status}`);
      } else {
        searchParams.append('drawer', `${status}`);
      }
    }

    if (!status && !force) {
      const drawer = searchParams.getAll('drawer');
      searchParams.delete('drawer');
      drawer.pop();
      for (const element of drawer) {
        searchParams.append('drawer', element);
      }
    }
    const newString = searchParams.toString();
    if (!status && force) {
      getWindow('');
      if (!isEmpty(getWindow(''))) {
        getWindow('history').go(-1);
      }
    } else {
      navigate(`${browserPath}?${newString}`);
    }
  }
};

export const useDrawer = (defaultState = []) => {
  const location = getWindow('location');
  if (!isEmpty(location)) {
    const { search } = getWindow('location');
    const searchParams = new URLSearchParams(search);
    const drawer = searchParams.getAll('drawer');
    return [drawer ? drawer : defaultState, navigateDrawer];
  } else {
    return [[], navigateDrawer];
  }
};

const NavigationDrawer = ({
  children,
  drawerStatus,
  header = 'Back',
  onClose = false,
  menuHide = false,
  cart = false,
  close = false,
}) => {
  const isApp = useContext(AppContext);
  const { iOS, tallDevice } = getViewingPlatform();
  return (
    <Drawer
      open={!!drawerStatus}
      style={
        menuHide && {
          zIndex: 1402,
        }
      }
      onClose={() => navigateDrawer(false, true)}
      variant={'temporary'}
    >
      {iOS && tallDevice && header && (
        <div style={{ height: 38, marginBottom: 0, backgroundColor: '#ffffff' }}></div>
      )}
      <div className={styles.drawerContainer} data-menuhide={menuHide} data-app={isApp}>
        {close && (
          <span
            onClick={() => (onClose ? onClose(false) : navigateDrawer(false))}
            className={styles.closeButton}
          >
            <img src={closeSymbol} alt="Close" />
          </span>
        )}
        {header === '' ? (
          <></>
        ) : (
          <button
            className={styles.appStoreDetailsAllStores}
            style={{ top: iOS && tallDevice && header !== '' ? 36 : 0 }}
            onClick={() => (onClose ? onClose(false) : navigateDrawer(false))}
            data-ios={iOS}
          >
            <div className={iOS ? styles.drawerCartIconRegularIos : styles.drawerCartIconRegular}>{header}</div>
            {cart && (
              <div className={iOS ? (tallDevice ? styles.drawerCartIconTallIos : styles.drawerCartIconRegularIos) : styles.drawerCartIconRegular}>
                <CartIcon />
              </div>
            )}
          </button>
        )}
        <div className={styles.appDetailsBody} data-header={header === ''}>
          {!!drawerStatus && children}
        </div>
      </div>
    </Drawer>
  );
};

export default NavigationDrawer;
