// Libs
import React, { useState } from 'react';
// Assets
import styles from './products.module.scss';
import locale from '../../../locale/locale';
import * as PropTypes from 'prop-types';
// Components
import Tiles from '../tiles/tiles';
import Product from '../cards/product';

const Products = ({ data, variant = null }) => {
  const [showMobile, setShowMobile] = useState(false);
  return (
    <div className={styles.productsSection} data-show={showMobile} data-name={data.name}>
      {data.heading && <h2 className={styles.productsHeading}>{data.heading}</h2>}
      <Tiles columns={3}>
        {data.products &&
          data.products.map((product,i) => {
            return (
              <Product
                product={product}
                variant={variant}
                key={`${product.id}-${i}`}
              />
            );
          })}
      </Tiles>
      {data.products.length > 3 && !showMobile && (
        <div className={styles.productsListLoadMore}>
          <button
            tabIndex="0"
            className={styles.productsListLoadMoreLink}
            onClick={() => {
              setShowMobile(true);
            }}
          >
            {locale.loftLandingPage.buttons.loadMoreButton}
          </button>
        </div>
      )}
    </div>
  );
};

export default Products;
