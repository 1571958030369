import React, { useMemo } from 'react';
import { noop } from 'lodash';
import * as PropTypes from 'prop-types';

/**
 * A declarative way to set a rendered component to be memo'd.
 * @param children
 * @param digest
 * @param deps
 * @returns {*}
 * @constructor
 */
const Memoized = ({ children, digest, deps }) => {
  useMemo(digest, deps);
  return useMemo(() => (<>
    {children}
  </>), deps);
};

Memoized.defaultProps = {
  deps: [],
  digest: noop,
};

Memoized.propTypes = {
  deps: PropTypes.arrayOf(PropTypes.any).isRequired,
  digest: PropTypes.func,
};

export default Memoized;
