// Libs.
import React, { useContext, useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { get, has } from 'lodash';
// Hooks.
import { useStateValue } from '../../store/state';
import { useStoresStaticQuery } from '../../hooks/useStoresStaticQuery';
// State.
import { AppContext } from '../layout/AppLayout';
import { AddItem } from '../../store/cart/action-creators';
import { CloseModal } from '../../store/modal/action-creators';
import { getStore } from '../../libs/store/store-utils';
import { validateClassScheduleAvailability } from '../../libs/class/class-validator';
import { dateAndTimeWithTimezoneCorrection } from '../../libs/form/dates';
import navigate from '../../libs/navigate';
// Deps.
import ErrorMessages from '../message/error-messages';
import BookingClassItem from './booking-class-item';
import AppHidden from '../app/common/app-hidden';
import FormLoader from '../common/loaders/form-loader';
import ButtonLoader from '../common/loaders/button-loader';
// Assets.
import styles from './booking-cooking-class.module.scss';
import locale from '../../locale/locale';
import pageAliases from '../../data/pageAliases';
import appAliases from '../../data/appAliases';
import { addNotification } from '../../libs/notify';

const BookingCookingClass = ({
  classSchedule,
  variation,
  selectStoreId,
  confirmState,
  onClose = false,
  limitByDate = false,
  virtual
}) => {
  // Hooks
  const stores = useStoresStaticQuery();
  // State
  const [, dispatch] = useStateValue();
  const isApp = useContext(AppContext);
  // Local state
  const [submitTarget, setSubmitTarget] = useState('');
  const [currentStore, setCurrentStore] = useState(undefined);
  const [submission, setSubmission] = useState(false);
  const [cartAdded, setCartAdded] = useState(false);
  // Obtain fallback class schedule if missing.
  if (!selectStoreId) {
    if (get(confirmState, 'selected.class', null)) {
      selectStoreId = confirmState.selected.store;
    }
  }
  const { availability = {} } = classSchedule;
  // In case of state slowness, ensure proper fallbacks are enforced
  Object.assign(availability, {
    seats: availability.seats >= 0 ? availability.seats : classSchedule.availableSeats,
    startDate: availability.startDate || classSchedule.startDateRaw,
    startTime: availability.startTime || classSchedule.startTime,
    endTime: availability.endTime || classSchedule.endTime,
  });
  const errorMessages = validateClassScheduleAvailability(classSchedule, null, 'booking-modal');

  // Methods.
  const handleSubmit = (e) => {
    setSubmission(true);
    e.preventDefault();
    console.log(submitTarget);
    const nativeEvent = e.nativeEvent;
    const formElements = Array.from(nativeEvent.target.elements);
    const formInputs = formElements.filter((element) => {
      return ['number', 'hidden'].includes(element.type);
    });

    const id = formInputs[0] ? formInputs[0].value : null;
    const name = formInputs[1] ? formInputs[1].value : '';
    const description = formInputs[2] ? formInputs[2].value : '';
    const guestQuantity = parseInt(formInputs[3] ? formInputs[3].value : 0);
    const pairingQuantity = parseInt(formInputs[4] ? formInputs[4].value : 0);
    const cartItem = {
      id,
      loftId: classSchedule.loftId,
      storeName: classSchedule.store.title,
      name,
      description,
      guests: guestQuantity,
      pairing: pairingQuantity,
      startDate: availability.startDate,
      startTime: availability.startTime,
      endTime: availability.endTime,
    };

    // Add cart item to cart store.
    if (guestQuantity > 0) {
      if (cartAdded && isApp) {
        addNotification(locale.classBooking.successAddToCart, 'success');
      }
      dispatch(AddItem(cartItem));
      // Set active if callback was provided.
      if (submitTarget === 'addToCart' || submitTarget === '') {
        dispatch(CloseModal());
        onClose && onClose(false);
        setCartAdded(false);
      } else if (submitTarget === 'checkout') {
        setTimeout(() => {
          navigate(isApp ? appAliases.appCheckout : pageAliases.classesCheckout);
        }, 100);
      }
    } else {
      // LintItem state will handle and display error in time for this.
    }

    if (variation === 'store') {
      // Give the effect of the cart adding taking some time.
      setTimeout(() => {
        setSubmission(false);
        setCartAdded(true);
        // After success, clear out the cart message shortly after.
        setTimeout(() => {
          setCartAdded(false);
        }, 3000);
      }, 1500);
    } else {
      setSubmission(false);
    }
  };

  // Handle state changes on visible classes.
  useEffect(() => {
    const storeId = parseInt(selectStoreId) || get(confirmState, 'selected.store.nid', null);
    if (storeId > 0) {
      setCurrentStore(getStore(stores, parseInt(storeId)));
    }
  }, [selectStoreId, confirmState]);

  return (
    <>
      <AppHidden>
        {variation === 'confirm' && (
          <div className={styles.bookingCookingClassNextClass}>
            <h3>Next class:</h3>
            <dl>
              {!limitByDate && get(confirmState, 'options.dates', []).length <= 1 && (
                <>
                  <dt>Date</dt>
                  <dd>
                    {dateAndTimeWithTimezoneCorrection(
                      availability.startDate,
                      availability.startTime
                    ).format('LL')}
                  </dd>
                </>
              )}
              {get(confirmState, 'options.times', []).length <= 1 && (
                <>
                  <dt>Time</dt>
                  <dd>
                    {availability.startTime} - {availability.endTime}
                  </dd>
                </>
              )}
              {has(currentStore, 'address1') && (
                <>
                  <dt>Location</dt>
                  <dd>
                    {currentStore.address1}
                    {currentStore.address1 !== '' && currentStore.city !== '' ? ', ' : ''}
                    {currentStore.city}
                  </dd>
                </>
              )}
              {/*<div>Date {classSchedule.startDateRaw}</div>*/}
              {availability.seats > 0 && (
                <>
                  <dt>Availability</dt>
                  <dd>{availability.seats} spots left</dd>
                </>
              )}
            </dl>
          </div>
        )}
      </AppHidden>
      <form className={styles.bookingCookingClass} name="booking-form" onSubmit={handleSubmit}>
        {submission && <FormLoader />}
        {errorMessages.length > 0 && <ErrorMessages messages={errorMessages} />}
        {availability.seats > 0 && (
          <BookingClassItem classSchedule={classSchedule} realAvailability={availability.seats} isVirtual={virtual} />
        )}
        <div className={styles.bookingCookingClassButtonsWrapper}>
          {availability.booking && (
            <>
              {variation === 'confirm' && (
                <>
                  <button
                    onClick={() => {
                      setCartAdded(true);
                      setSubmitTarget('addToCart');
                    }}
                    className={styles.bookingCookingClassAddCartButton}
                  >
                    Add to cart
                    {submission && <ButtonLoader />}
                  </button>

                  <button
                    onClick={() => {
                      setSubmitTarget('checkout');
                    }}
                    className={styles.bookingCookingClassCheckoutButton}
                  >
                    Checkout
                    {submission && <ButtonLoader />}
                  </button>
                </>
              )}
              {variation === 'store' && (
                <>
                  <button
                    className={styles.bookingCookingClassBookButton}
                    onClick={() => navigate(pageAliases.loftLandingPage)}
                  >
                    {locale.classBooking.buttons.bookButton}
                    {submission && <ButtonLoader />}
                  </button>
                  <button
                    className={styles.bookingCookingClassClassesButton}
                    onClick={() => navigate(pageAliases.loftLandingPage)}
                  >
                    {locale.classBooking.buttons.allClassesButton}
                    {submission && <ButtonLoader />}
                  </button>
                  {cartAdded && (
                    <div className={styles.successMessages}>{locale.classBooking.success}</div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </form>
    </>
  );
};

BookingCookingClass.propTypes = {
  variation: PropTypes.string.isRequired,
  classSchedule: PropTypes.object,
  selectStoreId: PropTypes.number,
  confirmState: PropTypes.object,
  limitByDate: PropTypes.bool,
};

export default BookingCookingClass;
