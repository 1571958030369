// Libs.
import React from 'react';
// Assets.
import styles from './tiles.module.scss';

const Tiles = ({ children, columns = 3 }) => {
  return (
    <div className={styles.tilesWrapper}>
      <div className={styles.tiles} data-columns={columns}>
        {children}
      </div>
    </div>
  );
};

export default Tiles;
