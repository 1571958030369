// Libs
import React, {useContext} from 'react';

//Assets.
import styles from './price.module.scss';
import * as PropTypes from 'prop-types';
import {AppContext} from "../layout/AppLayout";

const CommonPrice = ({ price }) => {
  const label = String(price || '').split('.');
  const isApp = useContext(AppContext);

  return (
    <div className={styles.commonPriceWrapper}>
      <div className={styles.commonPrice} data-app={isApp}>
        <span>${label[0]}{isApp && '.'}</span>
        <span>{label[1] || '00'}</span>
      </div>
    </div>
  );
};

CommonPrice.defaultProps = {
  price: null,
};

CommonPrice.propTypes = {
  price: PropTypes.number,
};

export default CommonPrice;
