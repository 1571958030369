// @flow
import { baseLoftClientService } from './baseLoftClientService'

export class loftClientService extends baseLoftClientService {
  // Extended just to run the singleton instantiation separate from base clase,
  // this allow import without singleton instantiation at child classes.
}

// Initialize singleton instance.
const singleton = new loftClientService();
Object.freeze(singleton);

export default singleton;