// Libs
import React from 'react';
import * as PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { get } from 'lodash';
import SiteLink from '../site-link';
//Assets.
import styles from './card.module.scss';
import imgFallback from '../../../images/class_hero_image.png';
import iconArrowSvg from '../../../images/icons/icon-arrow.svg';
// Deps.

const Card = ({ image, title, link, time }) => {
  let cardImage = image ? get(image, 'fluid', get(image, 'teaser', null)) : null;

  // console.log('card', {
  //   image,
  //   cardImage,
  //   title,
  //   link,
  //   time,
  // });

  return (
    <div className={styles.card}>
      <SiteLink href={link}>
        {cardImage ? (
          <Img className={styles.cardItemImage} fluid={cardImage}/>
        ) : (
          <img className={styles.cardItemImage} src={imgFallback} alt="Placeholder"/>
        )}
      </SiteLink>
      <div className={styles.cardInfoContainer}>
        <SiteLink href={link} className={styles.cardInlineLink}>
          {title && (
            <h4 className={styles.cardTitle}>
              {title}
              <img className={styles.cardItemImage} src={iconArrowSvg} alt="Placeholder"/>
            </h4>
          )}
        </SiteLink>
        {time && <div className={styles.cardAdditional}>{time}</div>}
      </div>
    </div>
  );
};

Card.defaultProps = {
  image: null,
  title: null,
  link: null,
  time: null,
};

Card.propTypes = {
  image: PropTypes.object,
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  time: PropTypes.string,
};

export default Card;
