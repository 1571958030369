// Libs
import React from 'react';
import styles from './text-area.module.scss';


const TextArea = ({ data }) => {
  return (
      <div className={styles.textAreaBody}>
          {data.title && data.title !== '-' && <div className={styles.textAreaTitle}>{data.title}</div>}
          {data.body && data.body !== '-' && <div className={styles.textAreaContent}>{data.body}</div>}
      </div>
  );
};

export default TextArea;
