// Libs
import moment from 'moment';
// In memory memo cache, does not persist.
const expiringMemoCache = {};

const getExpiringMemo = (hash) => {
  // Get current time stamp
  const timestamp = moment();
  // Data to pass back, if not expired
  let data = null;

  // Check now if this API call was cached, and has not expired yet based on expiry time set. This
  // is an attempt to sudo-memoize this method, but, with an expiry time to ensure it's not
  // permanently memo'd!
  if (expiringMemoCache[hash]) {
    // If found and not expired, automatically return memo'd result to avoid network calls.
    if (expiringMemoCache[hash].expires > timestamp.format('x')) {
      data = expiringMemoCache[hash].data;
    } else {
      // Else, this data has expired, clean it out to free up memory.
      try {
        delete expiringMemoCache[hash];
      } catch (e) {
      }
    }
  }

  return data;
};

const setExpiringMemo = (hash, data, expiry) => {
  // Get current time stamp
  const timestamp = moment().add(expiry, 'ms');

  // We have a new call that finished, store in the memo'd with an expiry timestamp of set time.
  expiringMemoCache[hash] = {
    data,
    expires: timestamp.format('x'),
  };

  // Automatically clean out expire memos even if they're not called again to free memory
  setTimeout(() => {
    try {
      delete expiringMemoCache[hash];
    } catch (e) {
    }
  }, expiry + 1000); // 1s after expected expire check
};

export {
  getExpiringMemo,
  setExpiringMemo,
};
