// Libs
import React, { useContext } from 'react';
import * as PropTypes from 'prop-types';
import Img from 'gatsby-image';
import moment from 'moment';
import { isNull, defaultsDeep, uniqueId, get } from 'lodash';
// Hooks
import { useStateValue } from '../../store/state';
import { useStoresStaticQuery } from '../../hooks/useStoresStaticQuery';
import { getStore } from '../../libs/store/store-utils';
// State
import { AppContext } from '../layout/AppLayout';
import { CloseModal } from '../../store/modal/action-creators';
import { redirectKeyPressHandler } from '../../libs/accessibility';
import { dateAndTimeWithTimezoneCorrection } from '../../libs/form/dates';
// Deps
import BookingCookingClassConfirmState from './cooking-class-confirm-state';
import BookingCookingClass from './booking-cooking-class';
import AppShown from '../app/common/app-shown';
import AppHidden from '../app/common/app-hidden';
import Memoized from '../common/Memoized';
// Assets
import styles from './cooking-class-confirm.module.scss';
import imgFallback from '../../images/class_hero_image.png';

const BookingCookingClassConfirm = ({
                                      classSchedule,
                                      availableStore,
                                      limitByDate = false,
                                      close = true,
                                      onClose = false,
                                    }) => {
  // Hooks
  const stores = useStoresStaticQuery();
  // State
  const [, dispatch] = useStateValue();
  const isApp = useContext(AppContext);
  // Local state
  const [state, setState] = BookingCookingClassConfirmState(
    classSchedule,
    availableStore,
    limitByDate,
  );
  // virtual
  let isVirtual =false;

  // Class data
  const image = get(classSchedule, 'class.image.fluid', null);
  const selectedStore = getStore(stores, get(state, 'selected.store'));
  const selectedClass = get(state, 'selected.class', null) || classSchedule;
  const onlyOneDateOption =
    !!selectedStore && state.options.dates.length <= 1 && !limitByDate;
  const formattedDateLabel = dateAndTimeWithTimezoneCorrection(
    selectedClass.startDateRaw,
    selectedClass.startTime,
  ).format('LL');
  const { availability = {} } = selectedClass || {};

  const checkVirtualClass = () => {
    if(selectedStore){
      //console.log(selectedStore.name);
      if(selectedStore.name === "Virtual Class"){
          isVirtual = true;
          return isVirtual;
      }else{
        return isVirtual;
      }
    }
  };
  
  const virtualClass =checkVirtualClass();
  //console.log(virtualClass);
  // Methods.
  // Close the modal.
  const handleCloseModal = () => {
    dispatch(CloseModal());
  };
  // Update the selected class store.
  const setStore = (store) =>
    setState(
      defaultsDeep(
        {
          selected: {
            store: parseInt(store) || '',
            // If the store changes, we must reset date/time selections
            date: '',
            time: '',
          },
        },
        state,
      ),
    );
  // Update the selected class date.
  const setDate = (startDateRaw) =>
    setState(
      defaultsDeep(
        {
          selected: {
            // If the date changes, we must reset time selections
            date: startDateRaw,
            time: '',
          },
        },
        state,
      ),
    );
  // Update the selected class time.
  const setTime = (id) =>
    setState(
      defaultsDeep(
        {
          selected: {
            time: id,
          },
        },
        state,
      ),
    );
  // Render the list of available time options.
  const renderTimeOptions = (item) => {
    const id = uniqueId(`time_${item.id}`);
    return (
      <div key={uniqueId(`time_${item.id}`)} className={styles.cookingClassConfirmRadioOption}>
        <input
          id={id}
          type="radio"
          name="booking-cooking-class__choose-time"
          value={item.id}
          checked={state.selected.time === item.id}
          onChange={(e) => setTime(e.target.value)}
        />
        <label className="radio-label" htmlFor={id}>
          {item.label}
        </label>
      </div>
    );
  };

  // Render.
  return (
    classSchedule && (
        <div className={styles.cookingClassConfirmOverlay} data-close={close && isApp}>
          <AppShown>
            <div className={styles.cookingClassConfirmImage}>
              {!isNull(image) ? (
                <Img className={styles.classDetailsHeroItemImage} fluid={image}/>
              ) : (
                <img
                  className={styles.classDetailsHeroItemImage}
                  src={imgFallback}
                  alt={`${classSchedule.title} sampling.`}
                />
              )}
            </div>
          </AppShown>
          <div className={styles.cookingClassConfirmContainer}>
            {close && (
              <span
                role="button"
                tabIndex="0"
                onClick={handleCloseModal}
                onKeyPress={redirectKeyPressHandler(handleCloseModal)}
                className={styles.cookingClassConfirmClose}
              />
            )}
            <AppShown>
              <h2 className={styles.cookingClassConfirmTitle}>{classSchedule.title}</h2>
            </AppShown>
            <AppHidden>
              <div className={styles.cookingClassConfirmClassNameContainer}>
                <h2 className={styles.cookingClassConfirmTitle}>Confirm Class</h2>
                <h3 className={styles.cookingClassConfirmSubtitle}>{classSchedule.title}</h3>
              </div>
            </AppHidden>
            {limitByDate && moment.isMoment(selectedClass.startDate) && (
              <h3 className={styles.cookingClassConfirmSubtitle}>{formattedDateLabel}</h3>
            )}
            {(state.options.stores.length >= 1 || selectedClass.availability.booking) && (
              <>
                <label
                  className={styles.cookingClassConfirmSelectorLabel}
                  htmlFor="booking-cooking-class__choose-location"
                >
                  {state.options.stores.length > 1 ? (
                    <span>Choose a location</span>
                  ) : (
                    <span>Location</span>
                  )}
                </label>
                <div className={styles.cookingClassConfirmSelector}>
                  <select
                    value={state.selected.store}
                    onChange={(e) => setStore(e.target.value)}
                    name="booking-cooking-class__choose-location"
                  >
                    {state.options.stores.length > 1 && (
                      <option key="default" value="">
                        Select
                      </option>
                    )}
                    {state.options.stores.map((item) => (
                      <option key={uniqueId(`store_${item.store.nid}`)} value={item.store.nid}>
                        {item.store.title}
                      </option>
                    ))}
                  </select>
                </div>
              </>
            )}
            {// If there are multiple date options, and we are not limiting by date, show this listing.
              state.options.dates.length > 1 && !limitByDate && (
                <>
                  <label
                    className={styles.cookingClassConfirmSelectorLabelDate}
                    htmlFor="booking-cooking-class__choose-date"
                  >
                    Choose a date
                  </label>
                  <div className={styles.cookingClassConfirmSelector}>
                    <select
                      value={state.selected.date}
                      onChange={(e) => setDate(e.target.value)}
                      name="booking-cooking-class__choose-date"
                    >
                      <option key="default" value="">
                        -- Select --
                      </option>
                      {state.options.dates.map((item) => (
                        <option key={uniqueId(`date_${item.id}`)} value={item.id}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </>
              )}
            <AppShown>
              {// Alternative, on the app, if there are no date options and we're not limiting dates... we need to know the selection date.
                state.options.times.length <= 1 && onlyOneDateOption && (
                  <div>{formattedDateLabel}</div>
                )}
              {(!state.options.dates.length || state.selected.date) &&
              selectedClass.startTime &&
              selectedClass.endTime &&
              get(selectedStore, 'address1') && (
                <div className={styles.cookingClassConfirmClassInfo}>
                  {state.options.times.length > 1 ? (
                    <>
                      <p className={styles.cookingClassConfirmSelectorLabelTime}>Choose a time</p>
                      <div className={styles.cookingClassConfirmSelectorTime}>
                        {state.options.times.map(renderTimeOptions)}
                      </div>
                    </>
                  ) : (
                    <div>
                      {selectedClass.startTime} - {selectedClass.endTime}
                    </div>
                  )}
                  {// Alternative, on the app, if there are no date options and we're not limiting dates... we need to know the selection date.
                    state.selected.time && onlyOneDateOption && <div>{formattedDateLabel}</div>}
                  {(state.options.times.length <= 1 || state.selected.time) && (
                    <>
                      <div>
                        {get(selectedStore, 'address1')}, {get(selectedStore, 'city')}
                      </div>
                      {availability.seats > 0 && (
                        <div className={styles.cookingClassConfirmLeft}>
                          {availability.seats} spots left
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
            </AppShown>
            <AppHidden>
              {state.options.times.length > 1 && (
                <>
                  <p className={styles.cookingClassConfirmSelectorLabelTime}>Choose a time</p>
                  <div className={styles.cookingClassConfirmSelectorTime}>
                    {state.options.times.map(renderTimeOptions)}
                  </div>
                </>
              )}
            </AppHidden>
            {!state.loading && !isNull(state.selected.class) && (
              <BookingCookingClass
                classSchedule={selectedClass}
                confirmState={state}
                limitByDate={limitByDate}
                variation="confirm"
                onClose={onClose}
                virtual={virtualClass}
              />
            )}
          </div>
        </div>
    )
  );
};

BookingCookingClassConfirm.defaultProps = {
  limitByDate: false,
  availableStore: null,
  classSchedule: null,
};

BookingCookingClassConfirm.propTypes = {
  limitByDate: PropTypes.bool,
  availableStore: PropTypes.number,
  classSchedule: PropTypes.object.isRequired,
};

export default BookingCookingClassConfirm;
