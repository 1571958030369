// Libs
import React, { useContext, useState } from 'react';
import * as PropTypes from 'prop-types';
// State
import { modalTypes } from '../../store/modal/action-types';
import { useStateValue } from '../../store/state';
import { OpenModal } from '../../store/modal/action-creators';
import { AppContext } from '../layout/AppLayout';
// Deps
import BookingCookingClassConfirm from './cooking-class-confirm';
import NavigationDrawer from '../../hooks/useNavigationDrawer';
// Assets
import locale from '../../locale/locale';
import styles from './booking-class-modal.module.scss';
import { numBookableSeatsInRelatedClasses } from '../../libs/class/class-format';

const BookingClassModal = ({ buttonClass, availableStore, classSchedule, limitByDate }) => {
  // State
  const [, dispatch] = useStateValue();
  // Local state
  const [drawer, setDrawer] = useState(false);
  const isApp = useContext(AppContext);
  // Class data.
  const { availability } = classSchedule;
  // If there are no available stores for this class, don't allow people to book it.
  const disabled =
    classSchedule.availability.locations < 2 &&
    !classSchedule.availability.booking &&
    numBookableSeatsInRelatedClasses(classSchedule).numLocations <= 0 &&
    numBookableSeatsInRelatedClasses(classSchedule).numSeats <= 0;

  let unavailableButtonLabel = locale.classBooking.buttons.soldOut;
  if (availability.isCancel) {
    unavailableButtonLabel = locale.classBooking.buttons.cancel;
  } else if (availability.isClosed) {
    unavailableButtonLabel = locale.classBooking.buttons.closed;
  }

  // Methods.
  // Toggle book activation.
  const toggle = (e) => {
    e.preventDefault();
    dispatch(
      OpenModal({
        id: `${classSchedule.id}_class_booking_modal`,
        type: modalTypes.SLIDEIN,
        content: (
          <BookingCookingClassConfirm
            classSchedule={classSchedule}
            availableStore={availableStore}
            limitByDate={limitByDate}
          />
        ),
      })
    );
  };

  // Render.
  return (
    <div className={styles.bookingClassModal}>
      <button
        className={buttonClass || styles.bookingClassModalButton}
        onClick={!isApp ? toggle : () => setDrawer(!drawer)}
        disabled={disabled}
        style={{
          opacity: disabled ? 0.3 : 1,
          cursor: disabled ? 'default' : 'pointer',
        }}
        dangerouslySetInnerHTML={{
          __html: disabled ? unavailableButtonLabel : locale.classBooking.buttons.bookButton,
        }}
      />
      <NavigationDrawer
        drawerStatus={drawer}
        header={'Back to class'}
        menuHide={true}
        cart={true}
        onClose={() => setDrawer(!drawer)}
      >
        <BookingCookingClassConfirm
          classSchedule={classSchedule}
          availableStore={availableStore}
          limitByDate={limitByDate}
          close={false}
          onClose={setDrawer}
        />
      </NavigationDrawer>
    </div>
  );
};

BookingClassModal.defaultProps = {
  classSchedule: null,
  buttonClass: '',
  availableStore: null,
  limitByDate: false,
};

BookingClassModal.propTypes = {
  buttonClass: PropTypes.string,
  classSchedule: PropTypes.object.isRequired,
  availableStore: PropTypes.number,
  limitByDate: PropTypes.bool,
};

export default BookingClassModal;
