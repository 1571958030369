import { isObject, get, uniq, keys, keyBy, values, size, uniqBy } from 'lodash';
import { filterUpcomingSchedules } from '../../libs/class/class-fetcher';

export const classFilterStoresType = {
  byNid: 'byNid',
  byDate: 'byDate',
};

/**
 * A means to extract related classes, and related storeIds shared by a given class schedule.
 * @param classSchedule
 * @param classList
 * @param relatedFilter
 * @returns {{availableStoreIds: [], relatedClasses: []}|{availableStoreIds: *, relatedClasses: *}}
 */
const getClassFilteredStores = (classSchedule, classList, relatedFilter = null) => {
  if (!isObject(classSchedule)) {
    return {
      relatedClasses: [],
      availableStoreIds: [],
    };
  }

  let availableStoreIds = [];
  let relatedClasses = [];
  let filterListBy = (classItem) => classItem.class.nid === parseInt(classSchedule.class.nid);

  let limitByDate = relatedFilter === classFilterStoresType.byDate;
  if (limitByDate) {
    filterListBy = (classItem) =>
      classItem.class.nid === parseInt(classSchedule.class.nid) &&
      classItem.startDateRaw === classSchedule.startDateRaw;
  }

  // Filter upcoming scheduled classes which match this classes class nid. The provided class
  // list is presorted and keyed by class id & date for optimizing filtering.
  const matchedClassDate = filterUpcomingSchedules(classList).filter(filterListBy);

  // If this class/date has siblings leverage them.
  if (matchedClassDate.length) {
    // Pool the siblings as related classes, based on filter type.
    relatedClasses = values(get(matchedClassDate[0], `_siblings.${limitByDate ? 'byDate' : 'byClassNid'}`, {}) || {});

    // If siblings are missing, attempt to fall back to the related classes from availability.
    if (!size(relatedClasses)) {
      relatedClasses = classSchedule.availability.relatedClasses || [];
    }

    // Always add the original class in case it wasn't present due to siblings grouping rules, if valid.
    if (filterListBy(classSchedule)) {
      relatedClasses.push(classSchedule);
    }

    // Ensure we're unique here.
    relatedClasses = uniqBy(relatedClasses,'loftId');

    // Now, with the sorted unique store classes, find those that match the given date.
    availableStoreIds = getClassListStoreIds(relatedClasses);
  }

  // if (availableStoreIds.length) {
  //   console.log(`getClassFilteredStores ${classSchedule.title} ${classSchedule.loftId}`, {
  //     matchedClassDate,
  //     relatedClasses,
  //     availableStoreIds,
  //   });
  // }

  return {
    relatedClasses,
    availableStoreIds,
  };
};

export const getClassListStoreIds = (relatedClasses = []) => uniq(
  uniq(keys(
    keyBy(
      relatedClasses,
      'store.nid'),
  ).map(id => parseInt(id))));

export default getClassFilteredStores;
