// Libs
import React from 'react';
import * as PropTypes from 'prop-types';
// Custom libs.
import { displayInteger, displayFraction } from './../../../libs/commerce/price-formatter';
// Assets
import styles from './line-item-total.module.scss';
import { addLineItemTotal } from '../../../libs/class/class-items-calculator';

const LineItemTotal = ({ label, item, symbol }) => {
  const itemWithTotal = addLineItemTotal(item);
  const { total } = itemWithTotal;

  return (
    <div className={styles.lineItemTotal}>
      {label && <div className={styles.lineItemTotalLabel}>{label}</div>}
      <div className={styles.lineItemTotalDetailsPrice}>
        <span>
          {symbol}
          {displayInteger(total)}
        </span>
        <span>{displayFraction(total)}</span>
      </div>
    </div>
  );
};

LineItemTotal.defaultProps = {
  symbol: '$',
  label: '',
};

LineItemTotal.propTypes = {
  symbol: PropTypes.string,
  label: PropTypes.string,
  item: PropTypes.object.isRequired,
};

export default LineItemTotal;
