import React, { useContext } from 'react';
import styles from './form-loader.module.scss';
import { AppContext } from '../../layout/AppLayout';
const FormLoader = () => {
  const app = useContext(AppContext);

  return (
    <>
      <div className={styles.formLoader} data-app={app} />
      <div className={styles.ldsRing} data-app={app}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </>
  );
};

export default FormLoader;
