import { useStaticQuery, graphql } from 'gatsby';

export const useCampaignLandingStaticQuery = () => {
  return useLoadCampaignLanding();
};

const useLoadCampaignLanding = () => {
  return useStaticQuery(graphql`
    query {
      hero: configPagesCampaign {
        id
        label
        nid: drupal_id
        relationships {
          image: field_hero_image {
            localFile {
              childImageSharp {
                fluid(maxHeight: 700, srcSetBreakpoints: [1200, 700]) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        publish: field_publish_hero
        subTitle: field_hero_summary {
          value
        }
        title: field_hero_title {
          value
        }
        body: field_hero_description {
          value
        }
      }
      products: configPagesCampaign {
        publish: field_publish_products
        relationships {
          products: field_longos_weekly_products {
            title
            description: body {
              value
            }
            price: field_product_price {
              value
            }
            summary: field_product_quality_descriptio {
              value
            }
            bonus: field_product_savings {
              value
            }
            points: field_points_awards
            relationships {
              image: field_product_images {
                localFile {
                  childImageSharp {
                    fluid(maxHeight: 330, srcSetBreakpoints: [330, 330]) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
      cta: configPagesCampaign {
        publish: field_publish_cta
        title: field_classes_cta_title {
          value
        }
        linkFindStore: field_feature_link_2 {
          title
          uri
        }
        linkShopOnline: field_feature_link {
          title
          uri
        }
      }
      featuredClass: configPagesCampaign {
        publish: field_publish_featured_class
        relationships {
          field: field_featured_class {
            nid: drupal_internal__nid
          }
        }
      }
      recipes: configPagesCampaign {
        publish: field_publish_recipes
        title: field_recipes_label {
          value
        }
        relationships {
          recipes: field_product_reference {
            title
            total: field_preparation_time_total_
            nid: drupal_internal__nid
            relationships {
              image: field_file_image {
                localFile {
                  childImageSharp {
                    fluid(maxHeight: 300) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
      department: configPagesCampaign {
        publish: field_publish_department
        relationships {
          field: field_department {
            description: field_department_feature_body {
              value
              format
              processed
            }
            title: field_department_feature_heading {
              value
            }
            link: field_department_feature_link {
              uri
              title
            }
            subTitle: field_department_feature_sub {
              value
            }
            relationships {
              image: field_department_image {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              portrait: field_department_portrait {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
      signUp: configPagesCampaign {
        publish: field_publish_signup
        description: field_campaign_sign_up_descripti {
          value
        }
        title: field_campaign_sign_up_title {
          value
        }
        link: field_feature_link_3 {
          uri
          title
        }
        relationships {
          image: field_feature {
            localFile {
              childImageSharp {
                fluid(maxHeight: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);
};
