// Libs
import React from 'react';
//Assets.
import styles from './signup.module.scss';
import Img from 'gatsby-image';
import SiteLink from '../site-link';
import { has } from 'lodash';

const SignUp = ({ signUp }) => {
  return (
    <div className={styles.signUp}>
      <div className={styles.signUpContainer}>
        <div className={styles.signUpWrap}>
          <h1 className={styles.signUpTitle}>{signUp.title}</h1>
          <p className={styles.signUpDescription}>{signUp.description}</p>
          <SiteLink className={styles.signUpButton} href={signUp.link.uri}>
            {signUp.link.title}
          </SiteLink>
        </div>
        <div className={styles.signUpImageWrap}>
          {has(signUp.image, 'fluid') && <Img fluid={signUp.image.fluid} />}
        </div>
      </div>
    </div>
  );
};

export default SignUp;
