// @flow
import { defaults, uniq } from 'lodash';
import { stringify } from 'querystring';
import { doRequest } from './doRequest';
import type {
  ClassNotifySubscribe,
  ClassNotifySubscribed,
  ClassScheduleEntity,
  ClassSchedulesAvailability,
  CustomHeaders,
  Headers,
  OrderClassCancel,
  OrderClassCancelled,
  OrderCollection,
  OrderEntity,
} from './flow-typed/loftClientServiceTypes';
import { baseApiPath, API_TYPE, envLevel, siteOriginIs, siteTypes, getApiPath } from '../libs/api';

export class baseLoftClientService {
  headers: Headers;
  headersPost: Headers;
  static instance: baseLoftClientService;

  constructor() {
    if (baseLoftClientService.instance) {
      return baseLoftClientService.instance;
    }

    this.headers = {
      'User-Agent': 'Longos Web SPA',
      'X-API-Key': 'df34cde4-f145-4334-98de-787d2844dbde',
      'Content-Type': 'application/json',
    };

    // Different API Key for live envs.
    if (envLevel() === 'live') {
      this.headers['X-API-Key'] = '24c43ae2-1eac-4e79-8924-cf5270c49242';
    }

    this.headersPost = {
      ...this.headers,
    };

    baseLoftClientService.instance = this;
  }

  /**
   * Process an API HTTP request.
   *
   * @param {string} method - HTTP method: GET, POST, PUT, DELETE.
   * @param {string} resource - REST API resource name.
   * @param {(Object | string)} data - Request payload object for POST or stringified query string for GET.
   * @param {CustomHeadersType} [customHeaders={}] - Custom HTTP headers.
   * @returns {Promise} - Promise that resolves API response data.
   * @memberof loftClientService
   */
  async doRequest(
    method: string,
    resource: string,
    data: Object | string,
    customHeaders: CustomHeaders = {},
  ) {
    let url = getApiPath(API_TYPE.loft, resource);
    let postData = data;

    if (method === 'GET') {
      url = url + '?' + encodeURI(data || '');
      postData = null;
    }

    const headers = defaults(customHeaders, data === null ? this.headers : this.headersPost);

    let errorObject = {};
    try {
      const result = await doRequest({
        headers,
        method,
        url,
        data: postData,
      });

      return result;
    } catch (requestError) {
      try {
        const responseError = await JSON.parse(requestError.message);
        errorObject = {
          ...responseError,
          requestStatus: requestError.requestStatus,
        };
      } catch (e) {
        errorObject = {
          message: requestError.message,
          requestStatus: requestError.requestStatus,
        };
      }

      // Massaged error object ready for consumption at components.
      throw errorObject;
    }
  }

  /**
   * Strinct stringify that only encodes mandatory query string component chars.
   *
   * Loft API do not support query string decoding as handled in Javascript
   * through encodeURIComponent function (which encode some non strinctly
   * reserved chars). Even when this function is the standard JS way to encode
   * query string component the HTTP spec states that sub-delims and ":" and
   * "@" are chars that could be optionally encoded, for detailed specs see:
   * https://www.456bereastreet.com/archive/201008/what_characters_are_allowed_unencoded_in_query_strings/
   * https://tools.ietf.org/html/rfc3986#section-3.4
   *
   * @param {Object} [queryStringMap={}] - Query string object with map query string key - values.
   * @returns {String} - Stringify query string with encoded components.
   * @memberof loftClientService
   */
  strictQueryStringify(queryStringMap: Object = {}) {
    return stringify(queryStringMap, '', '', { encodeURIComponent: escape });
  }

  /**
   * Get class detail.
   *
   * @param {classScheduleId} - Class schedule ID.
   * @returns {Promise<ClassScheduleEntity>} - Promise that resolves class item.
   * @memberof loftClientService
   */
  classScheduleGet(classScheduleId: number): Promise<ClassScheduleEntity> {
    return this.doRequest('GET', `classDetails/${classScheduleId}`);
  }

  /**
   * Get customer orders list.
   *
   * @param {string} customerId - Customer CRM ID.
   * @param {string} userToken - Authorization bearer token.
   * @param {number} [offset=0] - Pagination offset.
   * @param {number} [maxItems=10] - Max items per page.
   * @returns {Promise<OrderCollection[]>} - Promise that resolve a collection of Order entity objects.
   * @memberof loftClientService
   */
  customerOrdersGet(
    customerId: string,
    userToken: string,
    offset: number = 0,
    maxItems: number = 10,
  ): Promise<OrderCollection> {
    const customHeaders: CustomHeaders = {
      customerType: 'REGISTERED',
      crmContactId: customerId,
      Authorization: `Bearer ${userToken}`,
    };

    const queryString = {
      max: maxItems,
      offset: offset,
    };

    return this.doRequest('GET', 'user/orders', stringify(queryString), customHeaders);
  }

  /**
   * Get a customer order by ID.
   *
   * @param {string} customerId - Customer CRM ID.
   * @param {number} orderId - The order ID.
   * @param {string} userToken - Authorization bearer token.
   * @returns Promise<OrderEntity> - Promise that resolves order entity object.
   * @memberof loftClientService
   */
  customerOrderGet(customerId: string, orderId: number, userToken: string): Promise<OrderEntity> {
    const customHeaders: CustomHeaders = {
      customerType: 'REGISTERED',
      crmContactId: customerId,
      Authorization: `Bearer ${userToken}`,
    };

    return this.doRequest('GET', 'user/order/' + orderId, '', customHeaders);
  }

  /**
   * Subscribe customer (authenticated) to class availability notifications.
   *
   * @param {string} email - Customer email.
   * @param {number} classScheduleId - Class schedule ID.
   * @param {string} customerId - Customer CRM ID.
   * @param {string} userToken - Authorization bearer token.
   * @returns {Promise<ClassNotifySubscribed>} - Promise that resolves class subscription object.
   * @memberof loftClientService
   */
  customerClassNotifySubscribe(
    email: string,
    classScheduleId: number,
    customerId: string,
    userToken: string,
  ): Promise<ClassNotifySubscribed> {
    const customHeaders: CustomHeaders = {
      customerType: 'REGISTERED',
      crmContactId: customerId,
      Authorization: `Bearer ${userToken}`,
    };

    const subscription: ClassNotifySubscribe = {
      customerEmail: email,
      classScheduleId: classScheduleId,
    };

    return this.doRequest('POST', 'class/notification', subscription, customHeaders);
  }

  /**
   * Subscribe guest (anonymous) to class availability notifications.
   *
   * @param {string} email - Customer email.
   * @param {number} classScheduleId - Class schedule ID.
   * @returns {Promise<ClassNotifySubscribed>} - Promise that resolves class subscription object.
   * @memberof loftClientService
   */
  guestClassNotifySubscribe(
    email: string,
    classScheduleId: number,
  ): Promise<ClassNotifySubscribed> {
    const customHeaders: CustomHeaders = {
      customerType: 'GUEST',
    };

    const subscription: ClassNotifySubscribe = {
      customerEmail: email,
      classScheduleId: classScheduleId,
    };

    return this.doRequest('POST', 'class/notification', subscription, customHeaders);
  }

  /**
   * Cancel customer (authenticated) order class item.
   *
   * @param {number} orderItemId - Order item ID.
   * @param {string} email - Customer email.
   * @param {string} customerId - Customer CRM ID.
   * @param {string} userToken - Authorization bearer token.
   * @returns {Promise<OrderClassCancelled>}
   * @memberof loftClientService
   */
  customerOrderClassCancel(
    orderItemId: number,
    email: string,
    customerId: string,
    userToken: string,
  ): Promise<OrderClassCancelled> {
    const customHeaders: CustomHeaders = {
      customerType: 'REGISTERED',
      crmContactId: customerId,
      Authorization: `Bearer ${userToken}`,
    };

    const cancelOrderItem: OrderClassCancel = {
      customerEmail: email,
      orderItemId: orderItemId,
    };

    return this.doRequest('POST', 'cancelClass', cancelOrderItem, customHeaders);
  }

  /**
   * Get class schedules availability.
   *
   * @param {number[]} classScheduleIds - An array of class schedule IDs.
   * @returns - Promise<ClassSchedulesAvailability> - Promise that resolve class schedule availability object.
   * @memberof loftClientService
   */
  classScheduleAvailabilityGet(classScheduleIds: number[]): Promise<ClassSchedulesAvailability> {
    // We avoid to pass query string through strinfigy due Loft API is not
    // supporting properly query string decoding, issue reported at:
    // https://therefore.atlassian.net/browse/LON-701?focusedCommentId=77321&page=com.atlassian.jira.plugin.system.issuetabpanels%3Acomment-tabpanel#comment-77321
    const queryString = 'allData=true&classScheduleIds=' + uniq(classScheduleIds).join(',');
    return this.doRequest(
      'GET',
      'classSchedules/availability',
      queryString,
    );
  }
}
