import { useEffect } from 'react';
import { assign, get, compact, isObject, size } from 'lodash';
// Hooks
import useInterval from './useInterval';
import awaitHandler from '../libs/asyncHandler';
// State
import { useClassesStaticQuery } from './useClassesStaticQuery';
import getClassFilteredStores, {
  classFilterStoresType,
} from '../components/common/getClassFilteredStores';
// Custom Libs.
import { delayMins } from '../libs/delay';
import { FetchClassCollectionRealData } from '../libs/class/class-fetcher';

/**
 * A helper hook to obtain a classes real time availability.
 * @param originalItems [object]
 * @param setAvailabilityHash function
 * @param limitByDate boolean
 * @param intervalMins number
 */
const useClassAvailability = (
  originalItems = null,
  setAvailabilityHash = null,
  limitByDate = false,
  intervalMins = 3,
) => {
  const [{ byClassNid: classesByNid, byDate: classesByDate }] = useClassesStaticQuery();

  const loadClassRealData = async (classList) => {
    const [
      realClassData = {
        data: {},
      },
      error,
    ] = await awaitHandler(FetchClassCollectionRealData(classList));

    // if (error) console.error(error);
    const relatedFilter = limitByDate ? classFilterStoresType.byDate : null;
    const classes = limitByDate ? classesByDate : classesByNid;
    classList.map((classSchedule) => {
      /*********************************************************************************************
       Obtain Latest Availability Date
       ********************************************************************************************/
        // Pull the class availability, and set up expected defaults
      const { availability = {} } = classSchedule;
      // Attempt to pull latest API availability.
      const { relatedClasses = [], availableStoreIds = [] } = getClassFilteredStores(
        classSchedule,
        classes,
        relatedFilter,
      );
      // Flag to indicate when Loft real availability data is ready to use.
      const apiLoaded = size(realClassData.data) > 0;
      availability.apiLoaded = apiLoaded;

      /*
      Helper method to check if a given loft class was included in the API call, and if it has
      seats. If the API has not loaded yet, falling back to static seat data for this check.
       */
      const relatedClassHasSeats = ({ loftId, availableSeats }) => (
        apiLoaded ?
          isObject(realClassData.data[loftId]) && realClassData.data[loftId].seats > 0 :
          availableSeats > 0
      );

      /*********************************************************************************************
       Configure some essential options.
       ********************************************************************************************/
      // If the API was successful, attempt to pull in data.
      if (apiLoaded) {
        /*
        To avoid false positives, if for any reason the API was successful, and the current class
        was for some reason not found, we have to assume there is some limitations against the
        class, and thus as a precaution can default to 0 for sold out status until the API returns
        useful details.
         */
        const currentClassData = get(
          realClassData,
          `data[${classSchedule.loftId}]`,
          { seats: 0 });
        const seats = parseInt(currentClassData.seats);
        // In the event the API only returns partial data, do not muck up things and use smart fallbacks.
        assign(availability, {
          seats: seats >= 0 ? seats : availability.seats, // If we didn't get a valid int... ignore
          startDate: currentClassData.startDate || availability.startDateRaw,
          startTime: currentClassData.startTime || availability.startTime,
          endTime: currentClassData.endTime || availability.endTime,
          status: currentClassData.status || availability.status || 'Active',
        });
        // console.log(classSchedule.loftId, availability);
      }
      // As a precaution, ensure to cast seats to an int here.
      availability.seats = parseInt(availability.seats);
      // If we should be limiting this entries availability by date, slim down the list of results.
      availability.limitByDate = limitByDate;

      /*********************************************************************************************
       Determine related classes, stores, and locations.
       ********************************************************************************************/
      // Obtain a list of this classes related the focal class, possibly limited by date.
      availability.relatedClasses = relatedClasses
        .filter(({ startDateRaw, loftId }) => (
          (limitByDate ? startDateRaw === classSchedule.startDateRaw : true)
        ));
      /*
      Refresh the list of associated stores based on current related classes, and obtain count of
      active locations based on the related classes.
       */
      const locationIds = {};
      availability.relatedClasses.forEach((relatedClass) => {
        /*
        Check if this related class has seats. This helper method will know whether the API is
        loaded, and ensure that no false positives are passed, but should API not be loaded,
        consistently use static data as expected.
         */
        const { loftId, availableSeats, store: { nid: storeId } } = relatedClass;

        if (relatedClassHasSeats({ loftId, availableSeats })) {
          locationIds[storeId] = true;
        }
        if (apiLoaded) {
          /*
          To avoid false positives, if for any reason the API was successful, and the current class
          was for some reason not found, we have to assume there is some limitations against the
          class, and thus as a precaution can default to 0 for sold out status until the API returns
          useful details.
           */
          const currentClassInnerData = get(
            realClassData,
            `data[${relatedClass.loftId}]`,
            { seats: 0 });
          const seats = parseInt(currentClassInnerData.seats);
          // In the event the API only returns partial data, do not muck up things and use smart fallbacks.
          assign(relatedClass.availability, {
            seats: seats >= 0 ? seats : relatedClass.availability.seats, // If we didn't get a valid int... ignore
            startDate: currentClassInnerData.startDate || relatedClass.availability.startDateRaw,
            startTime: currentClassInnerData.startTime || relatedClass.availability.startTime,
            endTime: currentClassInnerData.endTime || relatedClass.availability.endTime,
            status: currentClassInnerData.status || relatedClass.availability.status || 'Active',
          });
          // console.log(relatedClass.loftId, relatedClass.availability);
        }

      });
      // If the current class wasn't included, it is valid as a location if there are seats.
      if (availability.seats > 0) {
        locationIds[classSchedule.store.nid] = true;
      }
      const locations = size(locationIds);

      const bookingClasses = [classSchedule, ...availability.relatedClasses];
      /*********************************************************************************************
       Finally, determine booking related flags and options.
       ********************************************************************************************/
      // Either the API returned data and we know available store count

      bookingClasses.forEach((relatedClass) => {
        const { availability } = relatedClass;
        // Now, ensure to update availability with revised lists.
        availability.storeIds = availableStoreIds;
        availability.locationIds = locationIds;
        availability.locations = locations;

        availability.booking =
          (apiLoaded &&
            ((!limitByDate && locations >= 1) ||
              (limitByDate && locations >= 1))) ||
          // Or the API didn't load for this class, assume given DB data.
          (!apiLoaded &&
            (availability.seats > 0 ||
              (availableStoreIds.length > 1)));

        // Or there are no related classes to be booked, and doesn't matter if there're other stores available.
        if (
          availability.booking &&
          !availability.seats &&
          Array.isArray(availability.relatedClasses) &&
          !availability.relatedClasses.length
        ) {
          availability.booking = false;
        }

        // Default if this was blank.
        if (!availability.status) {
          availability.status = 'Open';
        }

        // Determine if this was cancelled.
        availability.isCancel = availability.status === 'Cancelled';
        // Determine if this was closed.
        availability.isClosed = availability.status === 'Registration Closed';

        // Or the class was cancelled or closed, in any of this conditions should
        // be handled as sold out.
        if (availability.isCancel || availability.isClosed) {
          availability.seats = 0;
          availability.booking = false;
          availability.locations = 0;
        }

        availability.processed = true;
        availability.dateChange = false;
        availability.timeChange = false;

        // Flag date change.
        const realStartDate = get(classSchedule, 'availability.startDate', null);
        if (realStartDate && classSchedule.startDateRaw !== realStartDate) {
          availability.dateChange = true;
        }

        // Flag time change.
        const realStartTime = get(classSchedule, 'availability.startTime', null);
        if (realStartDate && classSchedule.startTime !== realStartTime) {
          availability.timeChange = true;
        }
      })


      // Override startDateAndTime with real date / time data.
      // classSchedule.startDateAndTime = dateAndTimeWithTimezoneCorrection(realStartDate, realStartTime);

      // Enforce the update for watchers.
      classSchedule.availability = availability;

      return classSchedule;
    });

    // Propagate availability hash to component (using availability) local
    // state so in case of memoized / lazy load the hash could be used to
    // trigger render refresh.
    if (typeof setAvailabilityHash === 'function') {
      setAvailabilityHash(realClassData.hash);
    }

    return classList;
  };

  /**
   * Handle class real data load for class list.
   *
   * @returns {Promise<*>}
   */
  const classListRealDataProcess = () => {
    let classList = originalItems || null;

    if (!classList) {
    } else {
      if (!Array.isArray(originalItems)) {
        classList = compact([originalItems]);
      }
      if (!classList.length) {
      } else {
        loadClassRealData(classList).catch((e) => {
        });
      }
    }
  };

  useInterval(classListRealDataProcess, delayMins(intervalMins));
  useEffect(classListRealDataProcess, [originalItems]);
};

export default useClassAvailability;
