// Libs
import { useState, useEffect, useContext } from 'react';
import { find, uniqBy, values, sortBy, get } from 'lodash';
import moment from 'moment';
// State
import { sortClassesBy, filterUpcomingSchedules } from '../../libs/class/class-fetcher';
import { AppContext } from '../layout/AppLayout';
// Deps
import { useDefaultStore } from '../../hooks/useDefaultStore.js';
import { classDateFormat } from '../../libs/class/class-format';
import useClassAvailability from '../../hooks/useClassAvailability';

const BookingCookingClassConfirmState = (classSchedule, availableStore, limitByDate = true) => {
  const isApp = useContext(AppContext);
  const {
    availability: { storeIds: availableStores = [], relatedClasses = [] },
  } = classSchedule;
  const [state, setState] = useState({
    loading: false,
    // Get the list of all upcoming classes
    upcomingClasses: filterUpcomingSchedules(sortClassesBy(relatedClasses)),
    // Current user selections
    selected: {
      class: null,
      date: '',
      time: '',
      store: getInitialStoreId(),
    },
    // Available options based on selections
    options: {
      dates: [],
      times: [],
      // To hold list of store options on load
      stores: [],
    },
  });
  const [availabilityHash, setAvailabilityHash] = useState('');

  const { storeId, defaultStoreId } = useDefaultStore();
  const selectedClass = get(state, 'selected.class', null) || classSchedule;

  useClassAvailability(classSchedule, setAvailabilityHash, limitByDate, isApp ? 0.1 : 0.1);
  /**
   * Update the list of date options for the current selected store, or pick the only option.
   *
   * NOTE! selected.store is already a parsed Int to ensure compatibility with checks below.
   *
   * @param newState
   */
  const updateDateOptions = (newState) => {
    const { upcomingClasses, options, selected } = newState;
    const newOptions = {};

    const availableClasses = upcomingClasses.filter((item) => item.store.nid === selected.store);

    // If we only have a single option, set it and don't waste processing needlessly.
    if (availableClasses.length === 1) {
      selected.class = availableClasses[0];
      options.dates = [];
    } else {
      // Update available date options from available classes, and reduce duplicates
      availableClasses.forEach((item) => {
        // Can only match dates for current store selection
        if (item.store.nid === selected.store) {
          newOptions[item.startDateRaw] = {
            id: `${item.startDateRaw}/${item.nid}`,
            label: classDateFormat(item),
          };
        }
      });
      options.dates = values(newOptions);
    }

    // If from the reduced list there is only one date, select it by default, could have
    // multiple times associated to it in the next selection steps.
    if (options.dates.length === 1) {
      selected.date = options.dates[0].id;
    }

    // console.log('updateDateOptions', {
    //   upcomingClasses,
    //   selectedStore: selected.store,
    //   availableClasses,
    //   dates: options.dates,
    // });
  };

  /**
   * Update the list of time options for the current selected date, or pick the only option.
   *
   * @param newState
   */
  const updateTimeOptions = (newState) => {
    const { upcomingClasses, options, selected } = newState;
    const newOptions = {};
    const targetStartDate = selected.date.split('/')[0];

    const availableClasses = upcomingClasses.filter(
      (item) => item.store.nid === selected.store && item.startDateRaw === targetStartDate,
    );

    // If we only have a single option, set it and don't waste processing needlessly.
    if (availableClasses.length === 1) {
      selected.class = availableClasses[0];
      options.times = [];
    } else {
      // Update available time options from available classes, and reduce duplicates
      availableClasses.forEach((item) => {
        if (item.startDateRaw === targetStartDate) {
          newOptions[item.unixTimestamp] = {
            id: `${item.unixTimestamp}/${item.nid}`,
            label: `${item.startTime} - ${item.endTime}`,
          };
        }
      });
      options.times = values(newOptions);
    }

    // If from the reduced list there is only one date, select it by default, could have
    // multiple times associated to it in the next selection steps.
    if (options.times.length === 1) {
      selected.time = options.times[0].unixTimestamp;
    }

    // console.log('updateTimeOptions', {
    //   upcomingClasses,
    //   selectedDate: targetStartDate,
    //   availableClasses,
    //   times: options.times,
    // });
  };

  const refreshFilterOptions = () => {
    const newState = { ...state, loading: false };
    const { upcomingClasses, selected, options } = newState;

    // Reset selected class to determine it is't still viable based on options
    selected.class = null;

    // If there's only on option total, default as selected store.
    if (upcomingClasses.length === 1) {
      selected.class = upcomingClasses[0];
    }

    // If a default store option is set, configure it now.
    if (!selected.store) {
      selected.store = getInitialStoreId();
    }

    // Update date options. If only a single entry matches, it will be selected.
    updateDateOptions(newState);

    // Update date options. If only a single entry matches, it will be selected.
    updateTimeOptions(newState);

    // If a time was selected, at this point there can be only one class to select.
    if (selected.time) {
      selected.class =
        find(upcomingClasses, (item) => `${item.unixTimestamp}/${item.nid}` === selected.time) ||
        null;
    }
    // If by this point we don't have a class yet, but we only have a single date option, well auto-select it now.
    else if (!selected.class && selected.date && options.times.length <= 1) {
      selected.class =
        find(upcomingClasses, (item) => `${item.startDateRaw}/${item.nid}` === selected.date) ||
        null;
    }

    // Always, update state.
    setState(newState);

    // console.log('booking modal state', {
    //   upcomingClasses,
    //   selected,
    //   options: state.options,
    // });
  };

  /**
   * Determine the desired initial store ID on load. If availableStore was passed,
   * that means there is only one store available for this combo.
   * @returns {number}
   */
  function getInitialStoreId() {
    let initialSelectStoreId;

    if (availableStore) {
      initialSelectStoreId = availableStore;
    } else if (availableStores.length === 1) {
      initialSelectStoreId = availableStores[0];
    } else if (relatedClasses.length === 1) {
      initialSelectStoreId = relatedClasses[0].store.nid;
    } else if (availableStores.includes(storeId)) {
      // If user preferred store is available in options default to this.
      initialSelectStoreId = storeId;
    } else if (availableStores.includes(defaultStoreId)) {
      // Otherwise default to nearest store.
      initialSelectStoreId = defaultStoreId;
    }

    return initialSelectStoreId;
  }

  // When selected time change, select the class.
  useEffect(
    () => {
      setState({ ...state, loading: true });
      setTimeout(refreshFilterOptions, 100);
    },
    // Due to the type of options that can be selected, the selected.class is only ever
    // updated by this useEffect, and therefore we should not be watching that variable
    // for state changes, else this re-triggers needlessly.
    [state.selected.store, state.selected.date, state.selected.time],
  );

  // On load, if list of classes, or, if availability changes, revise the essential state items.
  useEffect(() => {
    const newState = { ...state };
    const { upcomingClasses, selected } = newState;

    // Refresh the list of upcomingClasses from any mutations in the availability or listing.
    Object.assign(upcomingClasses, filterUpcomingSchedules(sortClassesBy(relatedClasses)));

    // Set list of sorted store locations
    const classByUniqueStore = uniqBy(
      upcomingClasses.filter(
        (upcomingClass) =>
          (Array.isArray(selectedClass.availability.storeIds) &&
            selectedClass.availability.storeIds.includes(upcomingClass.store.nid)) ||
          false,
      ),
      'store.nid',
    );
    newState.options.stores = sortBy(classByUniqueStore, ['store.title']);

    // Just in case options have changed based on availability.
    setTimeout(refreshFilterOptions, 100);

    // Finally, update state with relevant changes.
    setState(newState);
  }, [classSchedule, availabilityHash]);


  return [state, setState];
};

export default BookingCookingClassConfirmState;
