// Libs.
import React, {useEffect, useState} from 'react';
import styles from './command-bar.module.scss';
import { useStateValue } from '../../../store/state';
import { get } from 'lodash';
import cartFilledIcon from '../../../images/icons/icon-cart-filled.svg';
import cartEmptyIcon from '../../../images/icons/icon-cart-empty.svg';
import navigate from '../../../libs/navigate';

const CartIcon = () => {
  const [state] = useStateValue();
  const [cartItemCount, setCartItemCount] = useState(0);

  useEffect(() => {
    setCartItemCount( get(state, 'cart.loft.lineItemsCount', 0) );
  }, [state.cart]);

  const onCartClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (typeof window !== 'undefined') {
      navigate('/app/classes/cart/');
    }
  };

  return (
    <>
      {cartItemCount > 0 ? (
        <div
          className={styles.commandBarCart}
          onClick={onCartClick}
        ><img src={cartFilledIcon} alt="My Cart"/></div>
      ) : (
        <div className={styles.commandBarCart}>
          <img src={cartEmptyIcon} alt="My Cart"/></div>
      )}
    </>
  );
};

export default CartIcon;


