// Libs.
import React from 'react';
import { useClassesStaticQuery } from '../../hooks/useClassesStaticQuery';
import { get, isEmpty } from 'lodash';
// Components.
import ImageHero from '../common/image-hero/image-hero';
import Products from '../common/products/products';
import FindStoreCta from '../common/find-store-cta/find-store-cta';
import DepartmentSection from "../common/department-section/department-section";
import ClassListingFeatured from '../class/class-listing-featured';
import CampaignRecipes from '../campaign/campaign-recipes';
import SignUp from '../common/signup/signup';
import TextArea from './text-area';

// Assets.
import styles from '../../components/campaign/campaign-landing.module.scss';
import {useCampaignLandingStaticQuery} from "../../hooks/useCampaignLandingStaticQuery";
import {formatFieldsValue} from "../../libs/formatFromGraph";
import SEO from "../../components/layout/seo";
import Layout from '../../components/layout/layout';


const CampaignPageDynamic = ({ campaignData }) => {
   // const { campaign } = campaignData;
   //  console.log(campaignData);
    const featuredClassId = get(campaignData, "featuredClass.field.nid" , null);
    const [{ byClassNid }] = useClassesStaticQuery();
    let classSchedule;
    if (featuredClassId && byClassNid) {
      classSchedule = byClassNid.find((Class) => {
        return Class.class.nid === featuredClassId;
      });
    }
  return (
    <Layout>
      <SEO title={campaignData.title} />
      <div className={styles.campaignLanding}>
        {campaignData.hero.publish && <ImageHero data={campaignData.hero} />}
        {campaignData.testArea_1.publish && <TextArea data={campaignData.testArea_1} />}
        {campaignData.testArea_2.publish && <TextArea data={campaignData.testArea_2} />}
        {campaignData.testArea_3.publish && <TextArea data={campaignData.testArea_3} />}
        {campaignData.testArea_4.publish && <TextArea data={campaignData.testArea_4} />}
        {campaignData.testArea_5.publish && <TextArea data={campaignData.testArea_5} />}
        {campaignData.products.publish && <Products data={campaignData.products} />}
        {campaignData.cta.publish && <FindStoreCta cta={campaignData.cta} />}
        {campaignData.department.publish && <DepartmentSection content={campaignData.department.field}/>}
        {campaignData.featuredClass.publish && classSchedule && <ClassListingFeatured classSchedule={classSchedule} variation={'sm'} />}
        {campaignData.recipes.publish && <CampaignRecipes data={campaignData.recipes} />}
        {campaignData.signUp.publish && <SignUp signUp={campaignData.signUp} />}
      </div>
    </Layout>
  );
};

export default CampaignPageDynamic;




