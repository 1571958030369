// Libs
import React from 'react';
import * as PropTypes from 'prop-types';
import { get, isEmpty, has } from 'lodash';
// State
import { useImageStaticQuery } from '../../../hooks/useImagesStaticQuery';
// Deps
import ListingTitle from '../listing-title/listing-title';
import Memoized from '../Memoized';
import Img from 'gatsby-image';

// Assets
import styles from './image-hero.module.scss';

const ImageHero = ({ data }) => {
  const imageData = useImageStaticQuery(data.image, data.cmsImage);
  const image = get(data,'image.fluid', get(imageData,'childImageSharp.fluid'));

  return (
    <Memoized deps={[data]}>
      <div className={styles.videoHero}>
        {has(data, 'imageHeroText') &&
        data.imageHeroText &&
        ((has(data, 'showHeroText') && data.showHeroText) || !has(data, 'showHeroText')) && (
          <div className={styles.imageHeroTextContainer}>
            <div
              className={styles.imageHeroText}
              dangerouslySetInnerHTML={{ __html: data.imageHeroText }}
            />
          </div>
        )}
        {image !== null && <Img fluid={image}/>}
        <ListingTitle content={data}/>
      </div>
    </Memoized>
  );
};

ImageHero.defaultProps = {
  data: PropTypes.isObject,
};

export default ImageHero;
