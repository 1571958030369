// Libs
import React from 'react';
import { isEmpty } from 'lodash';
// Deps
import Layout from '../../components/layout/layout';
import SEO from '../../components/layout/seo';
import styles from '../../components/campaign/campaign-landing.module.scss';
import ImageHero from '../../components/common/image-hero/image-hero';
import CampaignPageDynamic from '../../components/campaign-dynamic/campaign-dynamic'

const CampaignPage =({ pageContext }) => {
const { campaign } = pageContext;
  return(
    <div>
    <CampaignPageDynamic campaignData={campaign}/>
    </div>
  )
}
/* const CampaignPage = ({ pageContext }) => {
  const { campaign } = pageContext;
  console.log(campaign);
  return (
    <Layout>
      <SEO title={campaign.title} />
      <div className={styles.campaignLanding}>
        {campaign.hero.publish && <ImageHero data={campaign.hero} />}
      </div>
    </Layout>
  );
}; */

export default CampaignPage;
