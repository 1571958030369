// Libs
import React from 'react';
import { has } from 'lodash';
import * as PropTypes from 'prop-types';
import Img from 'gatsby-image';
//Assets.
import styles from './product.module.scss';

export const ProductVariants = {
  showPoints: 'showPoints',
};

const Product = ({ variant = false, product }) => {
  // console.log('product',product);

  return (
    <div className={styles.productSection}>
      {has(product.image, 'fluid') && (
        <Img className={styles.productImage} fluid={product.image.fluid} />
      )}
      {product.title !== null && <h3 className={styles.productTitle}>{product.title}</h3>}
      {product.description !== null &&  <div className={styles.productDescription} dangerouslySetInnerHTML={{__html: product.description}}/>}
    
      <div className={styles.productPriceSection}>
        <div className={styles.productPricePartLeft}>
          {product.price !== null && <div className={styles.productPrice}>{product.price}</div>}
          {product.packageSize !== null && (
            <div className={styles.productPackageSize}>{product.packageSize}</div>
          )}
        </div>
        <div className={styles.productPricePartRight}>
          {/*{!variant && product.savings && <div className={styles.productBonus}>{product.savings}</div>}*/}
          {variant && variant === ProductVariants.showPoints && product.points && product.points > 0 && (
            <div className={styles.productBonus}>* {product.points} bonus points</div>
          )}
        </div>
      </div>
    </div>
  );
};

Product.defaultProps = {
  product: {
    image: null,
    title: null,
    description: null,
    price: null,
    weight: null,
    points: null,
    packageSize: null,
    savings: null,
  },
  variant: null,
};

Product.propTypes = {
  product: PropTypes.shape({
    image: PropTypes.object,
    title: PropTypes.string,
    description: PropTypes.string,
    price: PropTypes.string,
    weight: PropTypes.string,
    points: PropTypes.number,
    packageSize: PropTypes.string,
    savings: PropTypes.string,
  }).isRequired,
  variant: PropTypes.string,
};

export default Product;
