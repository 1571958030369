// Libs
import React, { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
// Deps
import CommonPrice from '../common/price';
// Assets
import styles from './class-listing-featured.module.scss';
import BookingClassModal from '../booking/booking-class-modal';
import { has } from 'lodash';
import Img from 'gatsby-image';
import imgFallback from '../../images/class_hero_image.png';
import useClassAvailability from '../../hooks/useClassAvailability';
import Memoized from '../common/Memoized';

const ClassListingFeatured = ({ classSchedule: originalEntity, variation = 'md' }) => {
  const className = variation === 'sm' ? styles.classListingFeaturedContainerSm : styles.classListingFeaturedContainer;

  //console.log(className);
  const [classSchedule, setClassSchedule] = useState(originalEntity);
  // Track a hash of the availability so layout effect mutates class items if
  // availability changes and ensure up to date availability render.
  const [availabilityHash, setAvailabilityHash] = useState('');
  // Keeps class up to date for availability, and mutates class items.
  useClassAvailability(originalEntity, setAvailabilityHash);
  // To support tracking object mutations across state.
  useEffect(() => {
    setClassSchedule({ ...originalEntity });
  }, [availabilityHash]);

  // console.log('ClassListingFeatured', classSchedule);

  return (
    <Memoized deps={[classSchedule]}>
      {has(classSchedule, 'class.title') && (
        <div className={styles.classListingFeatured}>
          <div className={className}>
            {has(classSchedule, 'class.image.fluid') ? (
              <Img
                className={styles.classListingFeaturedImage}
                fluid={classSchedule.class.image.fluid}
              />
            ) : (
              <img
                className={styles.classListingFeaturedImage}
                src={imgFallback}
                alt="Class Sample"
              />
            )}
            <div className={styles.classListingFeaturedDetails}>
              <div className={styles.classListingFeaturedDetailsFeatured}>Featured Class</div>
              <div className={styles.classListingFeaturedDetailsTitle}>{classSchedule.title}</div>
              <div className={styles.classListingFeaturedDetailsChef}>
                with {classSchedule.instructor.title}
              </div>
              <div className={styles.classListingFeaturedDetailsDescription}>
                {classSchedule.class.description}
              </div>
              <div className={styles.classListingFeaturedDetailsPrice}>
                <CommonPrice price={classSchedule.price}/>
              </div>
              <div className={styles.classListingFeaturedDetailsCaption}>
                <span dangerouslySetInnerHTML={{ __html: classSchedule.whatMake }}></span>
                {classSchedule.withWine && (
                  <span>Optional wine pairing for ${classSchedule.wineCost}</span>
                )}
              </div>
              <BookingClassModal classSchedule={classSchedule}/>
            </div>
          </div>
        </div>
      )}
    </Memoized>
  );
};

ClassListingFeatured.defaultProps = {
  classSchedule: null,
};

ClassListingFeatured.propTypes = {
  classSchedule: PropTypes.object,
};

export default ClassListingFeatured;
