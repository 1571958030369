/**
 * Display the integer part of a number
 * @param total
 * @returns {string}
 */
export function displayInteger(total) {
  let number = Number(total);
  const formatTotal = (number >= 0 ? number : 0).toFixed(2);
  const totalParts = String(formatTotal || '').split('.');
  return totalParts[0];
}

/**
 * Display the decimal part of a number
 * @param total
 * @returns {string}
 */
export function displayFraction(total) {
  let number = Number(total);
  const formatTotal = (number >= 0 ? number : 0).toFixed(2);
  const totalParts = String(formatTotal || '').split('.');
  return totalParts[1];
}
