// Libs
import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
// Assets
import styles from './listing-title.module.scss';

const ListingTitle = ({ content }) => {
  return useMemo(
    () => (
      <div className={styles.listingTitle}>
        <div className={styles.listingTitleContent}>
          {content.subTitle && <div className={styles.listingTitleDate}dangerouslySetInnerHTML={{__html: content.subTitle}}/>}
          {content.title && <div className={styles.listingTitleLabel} dangerouslySetInnerHTML={{__html: content.title}}/>}
          {content.body && <div className={styles.listingTitleBody} dangerouslySetInnerHTML={{__html: content.body}}/>}
        </div>
      </div>
    ),
    [content]
  );
};

ListingTitle.defaultProps = {
  content: null,
};

ListingTitle.propTypes = {
  content: PropTypes.object,
};

export default ListingTitle;
