// Libs
import React from 'react';
import { get } from 'lodash';
import Icon from 'gatsby-image';
import * as PropTypes from 'prop-types';
// Assets
import styles from './find-store-cta.module.scss';
import SiteLink from '../../common/site-link';
import { useImageStaticQuery } from '../../../hooks/useImagesStaticQuery';
import { linkShopOnline } from '../../../locale/locale';

const FindStoreCta = ({ cta }) => {
  const images = {
    'GG Truck.png': useImageStaticQuery('GG Truck.png'),
  };
  const icon = get(images[linkShopOnline.icon] || {}, 'childImageSharp.fluid', null);

  return (
    <div className={styles.findStoreCtaSection}>
      <div className={styles.findStoreCtaWrapper}>
        <div className={styles.findStoreCtaContentPart}>
          <div className={styles.findStoreCtaContent}>
            <h3
              className={cta.linkFindStore ? styles.findStoreCtaTitle : styles.findStoreCtaTitleNotOneButton}>{cta.title}</h3>
          </div>
          <div className={styles.findStoreCtaButtons}>
            {cta.linkFindStore && (
              <SiteLink className={styles.findStoreCtaBtnStore} href={cta.linkFindStore.uri}>
                {cta.linkFindStore.title}
              </SiteLink>
            )}
            {cta.linkShopOnline && (
              <SiteLink className={styles.findStoreCtaBtnOnline} href={cta.linkShopOnline.uri}>
                {icon !== null && (
                  <Icon
                    className={styles.findStoreCtaIcon}
                    fluid={icon}
                  />
                )}
                {cta.linkShopOnline.title}
              </SiteLink>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

FindStoreCta.defaultProps = {
  cta: null,
};

FindStoreCta.propTypes = {
  cta: PropTypes.shape({
    title: PropTypes.string,
    linkFindStore: PropTypes.shape({
      title: PropTypes.string,
      uri: PropTypes.string,
    }),
    linkShopOnline: PropTypes.shape({
      icon: PropTypes.string,
      title: PropTypes.string,
      uri: PropTypes.string,
    }),
  }),
};

export default FindStoreCta;
