// Libs.
import React from 'react';
import { uniqueId } from 'lodash';
import { formatRecipeFromNode } from '../../libs/formatFromGraph';
// Assets.
import styles from './campaign-recipes.module.scss';
// Components.
import Tiles from '../common/tiles/tiles';
import Card from '../common/cards/card';
import SiteLink from "../common/site-link";
import { campaignRecipes } from '../../locale/locale';

const CampaignRecipes = ({ data }) => {
  const recipes = data.recipes.map((recipe) =>  {
    return formatRecipeFromNode(recipe);
  });

  // console.log('recipes', recipes);

  return (
    <div className={styles.campaignRecipesSection}>
      <div className={styles.campaignRecipesHeaderContainer}>
        <h2 className={styles.campaignRecipesTitle}>{data.title}</h2>
        <div className={styles.campaignRecipesViewAllTop}>
          <SiteLink href={campaignRecipes.link.url}>{campaignRecipes.link.title}</SiteLink>
        </div>
      </div>
      <Tiles columns={3}>
        {recipes &&
          recipes.map((item) => {
            return (
              <Card
                key={uniqueId(`recipe_${item.nid}_`)}
                image={item.image}
                link={`/${item.alias}`}
                title={`${String(item.title || '').toUpperCase()}`}
                time={item.total}
              />
            );
          })}
      </Tiles>
      <div className={styles.campaignRecipesViewAllBottom}>
        <SiteLink href={campaignRecipes.link.url}>{campaignRecipes.link.title}</SiteLink>
      </div>
    </div>
  );
};

export default CampaignRecipes;
