import moment from 'moment';
import {sumBy} from 'lodash';

export const classDateFormat = (classSchedule, format = 'LL') => {
  return moment(classSchedule.startDateAndTime, 'YYYY-MM-DD h:mma').format(format);
};

export const classAvailabilitySummaryLine = (classSchedule) => {
  const { availability } = classSchedule;
  if (availability.locations > 1) {
    return `Offered at ${availability.locations} Loft locations`;
  } else {
    if (availability.seats > 0) {
      return `${availability.seats} spots left`;
    } else {
      if (availability.isCancel || availability.isClosed) {
        if (availability.relatedClasses.length > 0) {
          const {numLocations, numSeats} = numBookableSeatsInRelatedClasses(classSchedule);
          if (numLocations > 1) {
            return `Offered at ${numLocations} Loft locations`;
          } else if (numSeats > 0) {
            return `${numSeats} spots left`;
          } else {
            return availability.status;
          }
        } else {
          return availability.status;
        }
      } else {
        return 'Sold out';
      }
    }
  }
};

export const numBookableSeatsInRelatedClasses = (classSchedule) => {
  const otherClasses = classSchedule.availability.relatedClasses.filter(
    (relatedClass) =>
      relatedClass.loftId !== classSchedule.loftId && relatedClass.availability.seats > 0
  );

  return {numLocations: otherClasses.length, numSeats: sumBy(otherClasses, 'availability.seats')|| -1};
};
