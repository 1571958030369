// Libs.
import React, {useContext, useEffect} from 'react';
import * as PropTypes from 'prop-types';
// Deps.
import ErrorMessages from '../message/error-messages';
import LineItemQuantity from '../common/commerce/line-item-quantity';
import LineItemTotal from '../common/commerce/line-item-total';
// Hooks.
import useClassLineItemQuantity from '../../hooks/useClassLineItemQuantity';
// Assets.
import styles from './booking-class-item.module.scss';
import { classDateFormat } from '../../libs/class/class-format';
import { dateAndTimeWithTimezoneCorrection } from '../../libs/form/dates';
import AppShown from "../app/common/app-shown";
import AppHidden from "../app/common/app-hidden";
import {AppContext} from "../layout/AppLayout";

const BookingClassItem = ({ classSchedule, realAvailability, isVirtual }) => {
  const { nid, title, startTime, endTime, price, withWine, wineCost, availability = {} } = classSchedule;
  const lineItem = useClassLineItemQuantity({
    availableSeats: realAvailability,
    guestCost: price,
    pairingCost: wineCost,
  });
  const description = `${dateAndTimeWithTimezoneCorrection(availability.startDate, availability.startTime).format('MM/DD/YYYY')} ${
    availability.startTime
  } to ${availability.endTime}`;
  const isApp = useContext(AppContext);
  // Trigger validations if cooking class or real availability change.
  useEffect(() => {
    lineItem.guestChange();
  }, [nid, realAvailability]);

  return (
    <div className={styles.bookingClassItemContainer} data-app={isApp}>
      <AppHidden>
        <div className={styles.bookingClassItemDetailsPrice}>
          <LineItemTotal label="Total" item={lineItem.compose()}/>
        </div>
      </AppHidden>
      <AppShown>
        <div className={styles.bookingClassItemDetailsAppPrice}>
          <div>Total</div>
          <div className={styles.bookingClassItemDetailsPrice}>
            <LineItemTotal label="Total" item={lineItem.compose()}/>
          </div>
        </div>
      </AppShown>
      <ErrorMessages messages={lineItem.errorMessages}/>
      <input type="hidden" name="classId" value={nid}/>
      <input type="hidden" name="name" value={title}/>
      <input type="hidden" name="description" value={description}/>
      <div className={styles.bookingClassItemFormRows} data-app={isApp}>
        <LineItemQuantity
          key="guests"
          name="guests"
          label="Number of guests"
          value={lineItem.guests}
          setCallback={lineItem.guestChange}
          isvirtual={isVirtual}
        />
        {withWine && (
          <>
            <LineItemQuantity
              key="pairing"
              name="pairing"
              label="Add wine pairing"
              value={lineItem.pairing}
              setCallback={lineItem.pairingChange}
              isvirtual={isVirtual}
            />
            {/*<span className={styles.bookingClassItemCaption}>
              Must be 19 or older. Proof of age may be required.
            </span>*/}
          </>
        )}
      </div>
    </div>
  );
};

BookingClassItem.propTypes = {
  classSchedule: PropTypes.object.isRequired,
  realAvailability: PropTypes.number.isRequired,
};

export default BookingClassItem;
