// Libs.
import { get, orderBy, sortBy, values, isUndefined, flatten, find, has, size, uniq } from 'lodash';
// State.
import { ClassSortTypes } from '../../store/classFilters/action-types';
// Services.
import loftClient from '../../services/loftClientService';
import awaitHandler from '../asyncHandler';
import { uuidFromData } from '../uuid';
import { getExpiringMemo, setExpiringMemo } from '../expiringMemo';

// Define the maximum number of available classes will be checked in batches.
const LOFT_AVAILABILITY_CHECK_CAP = 200;
const LOFT_AVAILABILITY_API_CACHE_EXPIRY = 5000; // in milliseconds

// Filter class schedule items by selected store.
export function filterSchedulesByStoreId(classes, storeId, key = 'nid') {
  if (!storeId) {
    return [];
  }
  const items = classes.filter(
    (classSchedule) => classSchedule.dateIsActive && classSchedule.store[key] === parseInt(storeId),
  );
  return items;
}

export function filterSchedulesBySameLocation(classes, storeId) {
  if (!storeId) {
    return [];
  }
  const items = classes.filter((classSchedule) => classSchedule.locations.indexOf(storeId) !== -1);
  return items;
}

/**
 * Compares a list of class type ids against a class schedule node to see if they intersect.
 *
 * @param classTypeIds
 * @param classSchedule
 * @returns {boolean}
 */
export function checkForMatchingClassType(classTypeIds, classSchedule) {
  // First ensure we have valid props to check against, then access the IDs.
  const validProps =
    Array.isArray(classTypeIds) &&
    classTypeIds.length > 0 &&
    has(classSchedule, 'class.subCategoryIds') &&
    classSchedule.class.subCategoryIds.length > 0;

  // This combs the list of ids to make an array of matching IDs, or undefined is no matches.
  const findMatchingIds = find(classTypeIds, (categoryTid) => {
    if (classSchedule.class.subCategoryIds) {
      return classSchedule.class.subCategoryIds.indexOf(categoryTid.toString()) !== -1;
    }
  });

  // if (validProps && classTypeIds.indexOf(85) !== -1) {
  // console.log('hasMatchingClassType', {
  //   classTypeIds,
  //   classSubCategoryIds: classSchedule.class.subCategoryIds,
  //   validProps,
  //   findMatchingIds,
  // });
  // }

  return validProps && !isUndefined(findMatchingIds);
}

/**
 * Filter class schedule items by class type against an optional source class to exclude it from results.
 *
 * @param classScheduleItems
 * @param source
 * @returns {*}
 */
export function filterSchedulesByType(classScheduleItems, source) {
  const items = classScheduleItems.filter(
    (classSchedule) =>
      checkForMatchingClassType(source.class.subCategoryIds, classSchedule) &&
      source.nid === classSchedule.nid,
  );
  // console.log('filterSchedulesByType', items);
  return items;
}

/**
 * Provided a sorted list of classes, by Date Asc, or Alpha Asc.
 *
 * @param classes
 * @param classSorting
 */
export function sortClassesBy(classes, classSorting = ClassSortTypes.DATE) {
  if (!Array.isArray(classes) || classes.length === 0) {
    return [];
  }

  return sortBy(classes, (node) =>
    classSorting === ClassSortTypes.DATE ? node.unixTimestamp : node.title,
  );
}

// Filter upcoming scheduled classes.
export function filterUpcomingSchedules(classScheduleItems) {
  const items = Array.isArray(classScheduleItems)
    ? classScheduleItems.filter((classSchedule) => classSchedule.dateIsActive)
    : [];
  return items;
}

// Get next store class item.
export function getNextStoreSchedule(classScheduleItems, storeId) {
  const items = filterSchedulesByStoreId(classScheduleItems, storeId);
  const orderItems = orderBy(items, ['unixTimestamp'], ['asc']);
  return orderItems[0] || null;
}

/**
 * Get real time class schedule data for a collection by date (including siblings).
 *
 * @param {Array} classes - Class collection by date.
 * @returns {Promise<Object>} - A promise that resolves the class schedules
 * availabilities object.
 */
export async function FetchClassCollectionRealData(classes) {
  // Extract current class and siblings LoftIds due class with multiples
  // locations needs availability on all those to determine locations count.
  const loftIds = flatten(classes.map(extractClassGroupLoftIds));
  const totalLoftIds = loftIds.length;
  // Determine a hash of the current list of classes
  const idsHash = uuidFromData(loftIds);
  // Attempt to get a memo'd value from the API, if the results haven't expired yet.
  let hashedData = getExpiringMemo(idsHash);
  // For data storage.
  let data = {};

  if (!hashedData) {
    // Uncomment 2 following lines for performed requests analysis.
    // const currentDate = new Date();
    // console.log(`${currentDate.toISOString()} availability requests with IDs: `, loftIds);
    // Batch check the available class IDs. If the length is 0, this will bypass automatically.
    for (let i = 0; i < totalLoftIds; i += LOFT_AVAILABILITY_CHECK_CAP) {
      const nextSlice = i + LOFT_AVAILABILITY_CHECK_CAP;
      const classScheduleIds = uniq(loftIds.slice(
        i,
        nextSlice <= totalLoftIds ? nextSlice : totalLoftIds,
      ));
      // console.log('classScheduleAvailabilityGet', {
      //   loftIds, classScheduleIds,
      // });
      const [apiResult = {}] = await awaitHandler(
        loftClient.classScheduleAvailabilityGet(classScheduleIds),
      );
      const result = get(apiResult, 'classSchedules', {});

      // console.log('FetchClassCollectionRealAvailability:tick', {
      //   i,
      //   nextSlice,
      //   classScheduleIds,
      //   result,
      // });

      // Add the results to the collection of data
      Object.assign(data, result);
    }

    // Formula the desired return data.
    hashedData = {
      hash: uuidFromData(data),
      data,
    };

    // Add this as a expiring memo object, to avoid throttle API callbacks, defaulting to 10s.
    setExpiringMemo(idsHash, hashedData, LOFT_AVAILABILITY_API_CACHE_EXPIRY);
  }

  // console.log('FetchClassCollectionRealAvailability:final', { apiCacheHash, hashedData, data });

  return hashedData;
}

/**
 * Extract class group (current class and siblings) items Loft Ids.
 *
 * @param {Object} classItem - Class schedule item object.
 * @returns {Array<Number>} - Array with class schedules Loft Ids.
 */
export function extractClassGroupLoftIds(classItem) {
  if (!classItem) {
    return [];
  }
  let siblings = values(classItem._siblings.byClassNid);

  // If siblings are missing, attempt to fall back to the related classes from availability.
  if (!size(siblings)) {
    siblings = values(classItem.availability.relatedClasses);
  }

  // Extract class schedule siblings schedule ids.
  const loftIds = siblings.map((sibling) => parseInt(sibling.loftId));
  // Add base class schedule id.
  loftIds.push(parseInt(classItem.loftId));
  return uniq(loftIds);
}
