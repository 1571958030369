// Libs
import React from 'react';
import * as PropTypes from 'prop-types';
import { has } from 'lodash';
// Deps
import Img from 'gatsby-image';
import SiteLink from '../site-link';
// Assets
import styles from './department-section.module.scss';

const DepartmentSection = ({ content }) => {
  const departmentFeatureBody = content.description.processed;
  const bodyRemoveP = departmentFeatureBody.split('<p>').join('');
  const bodyFormatted = bodyRemoveP.split('</p>').join('');
  return (
    <div className={styles.storePromotedFeature}>
      <div className={styles.storePromotedFeatureLayout}>
        {content.image && (
          <Img
            className={styles.storePromotedFeatureLayoutLeft}
            fluid={content.image.fluid} />
        )}
        <div className={styles.storePromotedFeatureLayoutRight}>
          {content.portrait && (
            <Img
              alt="Bio Profile Pic"
              className={styles.storePromotedFeatureImageBio}
              fluid={content.portrait.fluid}
            />
          )}
          {content.title && (
            <h3 className={styles.storePromotedFeatureTitle}>{content.title}</h3>
          )}
          {content.subTitle && (
            <span className={styles.storePromotedFeaturePosition}>
              {content.subTitle}
            </span>
          )}
          {bodyFormatted && <blockquote className={styles.storePromotedFeatureCopy} dangerouslySetInnerHTML={{ __html: bodyFormatted }}></blockquote>}
          
          {has(content.image, 'link.uri') && content.image.link.uri !== '' && (
            <SiteLink
              className={styles.storePromotedFeatureLink}
              href={content.image.link.uri}
            >
              {content.image.link.title}
            </SiteLink>
          )}
        </div>
      </div>
    </div>
  );
};

DepartmentSection.defaultProps = {
  content: null,
};

DepartmentSection.propTypes = {
  content: PropTypes.shape({
    portrait: PropTypes.object,
    image: PropTypes.object,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    description: PropTypes.string,
    link: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
    })
  })
};

export default DepartmentSection;
