// Helper method to safely return an await, or an error if found.
export const awaitHandler = (promise) => {
  return promise
    .then(data => ([data, undefined]))
    .catch(error => {
      // console.error("Error: ", error);
      return Promise.resolve([undefined, error]);
    });
};

export default awaitHandler;
